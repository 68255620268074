import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showDialog)?_c(VDialog,{attrs:{"max-width":"800","scrollable":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('form-validator-wrapper',{on:{"submit":_vm.shippingHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('Shipping items')))]),_c(VIcon,{attrs:{"size":"32"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("close")])],1),_c(VDivider),_c(VCardText,[_c('order-ship-items-inputs',{attrs:{"order":_vm.order},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-end ma-2 flex-gap-8"},[_c('rule-validator',{attrs:{"value":_vm.form.items,"rules":_vm.rules.items}}),_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Send'))+" ")])],1)],1)]}}],null,false,3463267437)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }